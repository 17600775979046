import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {red} from '@material-ui/core/colors';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import {SERVER_URL} from '../../constants';
import {getProfileImage} from '../../util/Profile';
import moment from 'moment';
import parser from 'html-react-parser';
import EditIcon from '@material-ui/icons/Edit';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ChangeProjectDialog from './components/changeProjectDialog';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DocumentsList from '../../common/DocumentsList';
import {Box} from '@material-ui/core';
import EditableQuotesDialog from './components/EditableQuotesDialog';
import DeleteQuoteDialog from './components/DeleteQuoteDialog';
import {withStyles} from '@material-ui/core/styles';
import QuotePaymentScheduleTable from './components/QuotePaymentScheduleTable';
import CreateTransactionDialog from './components/CreateTransactionDialog';
import QuoteDetailsTable from './components/QuoteDetailsTable';
import QuoteMessagingDialog from './components/QuoteMessagingDialog';
import {Autorenew} from '@material-ui/icons';

const styles = (theme: $TSFixMe) => ({
  root: {
    maxWidth: '100%',
    textAlign: 'left',
    borderRadius: '1rem',
    marginBottom: 15,
  },

  media: {
    height: 300,
    width: '100 %',
    paddingTop: '56.25%', // 16:9
  },

  avatar: {
    backgroundColor: red[500],
  },

  actions: {
    flex: 'initial',
  },

  edit_input: {
    width: '100%',
  },

  btn_gropus: {
    marginTop: 10,
    '& button:first-child': {
      marginRight: 10,
    },
  },

  flex_item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  edit_icon: {
    cursor: 'pointer',
    marginLeft: 10,
  },

  flex: {
    display: 'flex',
    '& p': {
      margin: 0,
    },
  },

  edit_part: {
    marginTop: 20,
  },
});

const ProposalSmallCard = (props: $TSFixMe) => {
  const {classes} = props;
  const history = useHistory();
  const [editableDescription, setEditableDescription] = useState(false);
  const [showEditDescription, setShowEditDescription] = useState(false);
  const [proposal, setProposal] = useState<$TSFixMe>(null);
  const [attachments, setAttachments] = useState([]);
  const [profileName, setProfileName] = useState(null);
  const [rebuildingPdf, setRebuildingPdf] = useState(false);

  const getProposal = () => {
    fetch(`${SERVER_URL}v1/proposals/${props.proposalUid}`, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: localStorage.getItem('Authorization'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setProposal(res);
      });
  };

  useEffect(() => {
    getProposal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.proposalUid]);

  const getProfileName = () => {
    const name = proposal?.user?.name;
    if (!name) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Unknown"' is not assignable to ... Remove this comment to see the full error message
      setProfileName('Unknown');
      return;
    }
    const nameParts = name.split(' ');
    let firstName = null;
    let lastName = null;
    if (nameParts.length > 0) {
      firstName = name.split(' ')[0];
    }
    if (nameParts.length > 1) {
      lastName = name.split(' ')[1];
    }
    const displayName = `${firstName} ${lastName.charAt(0)}`;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    setProfileName(displayName);
  };

  const getTitle = () => {
    return (
      <Box mb={1}>
        <Grid container justifyContent="space-between">
          <Grid item xs={4}>
            <span style={{display: 'flex', alignItems: 'center'}}>
              <Tooltip title="View Profile">
                <Button
                  onClick={() => history.push(`/users/${proposal?.user?.id}`)}>
                  <Avatar
                    aria-label="recipe"
                    className={classes.avatar}
                    src={getProfileImage(proposal?.user, '100x100')}
                  />
                </Button>
              </Tooltip>
              <Typography variant="h6">
                {profileName ? profileName : null}
              </Typography>
            </span>
          </Grid>
          <Grid
            item
            xs={8}
            container
            spacing={1}
            alignContent={'flex-end'}
            alignItems={'flex-end'}
            justifyContent={'flex-end'}>
            <Grid item>
              <ChangeProjectDialog updateProjectQuote={updateProjectQuote} />
            </Grid>
            <Grid item>
              <DeleteQuoteDialog
                proposal={proposal}
                updateRequestForProposalState={
                  props.updateRequestForProposalState
                }
              />
            </Grid>
            <Grid item>
              <EditableQuotesDialog
                proposal={proposal}
                updateRequestForProposalState={
                  props.updateRequestForProposalState
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item>
            <Chip label={proposal.state} />
          </Grid>
          <Grid item>
            <Chip
              label={`POSTED: ${moment(proposal.createdDate).format(
                'MMMM Do YYYY',
              )}`}
            />
          </Grid>
          <Grid item>
            <Chip
              label={`COMPLETE BY: ${moment(proposal.proposedDate).format(
                'MMMM Do YYYY',
              )}`}
            />
          </Grid>
          <Grid item>
            <Chip
              label={`EXPIRES: ${moment(proposal.validUntilDate).format(
                'MMMM Do YYYY',
              )}`}
            />
          </Grid>
          <Grid item>
            {proposal.state === 'FUNDED' ? (
              <Chip
                label="Chosen Bid"
                style={{marginLeft: 5, backgroundColor: '#22ABF7'}}
              />
            ) : null}
          </Grid>
          <Grid item>
            <QuoteMessagingDialog conversationId={proposal.conversationId} />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const onChangeHandler = (event: $TSFixMe, editor: $TSFixMe) => {
    setProposal((prevState: $TSFixMe) => ({
      ...prevState,
      description: editor.getData(),
    }));
  };

  const getSubTitle = () => {
    return (
      <>
        <Grid container spacing={1}>
          {proposal.hasBeenViewed && (
            <Grid item>
              VIEWED BY HOMEOWNER <DoneAllIcon color={'secondary'} />
            </Grid>
          )}
          {proposal.insured && (
            <Grid item>
              Insured <DoneAllIcon color={'secondary'} />
            </Grid>
          )}
          {/*
          <Grid item>
            <PriceFlag proposal={proposal} onChange={updateProposal} />
          </Grid>
           */}
          {proposal.attachmentReferences &&
            proposal.attachmentReferences.length > 0 && (
              <Box mt={1}>
                <DocumentsList
                  attachmentReferences={proposal.attachmentReferences.filter(
                    (ref: $TSFixMe) => ref.key === 'project_document',
                  )}
                />
              </Box>
            )}
        </Grid>
        {proposal.state === 'DECLINED' && (
          <Grid
            container
            direction="column"
            spacing={2}
            style={{
              borderRadius: 4,
              backgroundColor: '#FFCCCB',
              marginTop: 10,
            }}>
            {proposal.declineReason && (
              <Grid item>
                <b>DECLINE REASON</b>: {proposal.declineReason}
              </Grid>
            )}
            {proposal.declineNote && (
              <Grid item>
                <b>NOTE:</b> {proposal.declineNote}
              </Grid>
            )}
          </Grid>
        )}
      </>
    );
  };

  const getDescription = () => {
    return editableDescription ? (
      <CardContent>
        <div className={classes.edit_part}>
          <CKEditor
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ placeholder: string; editor: typeof Classi... Remove this comment to see the full error message
            placeholder="Description"
            editor={ClassicEditor}
            data={proposal.description}
            config={{toolbar: []}}
            onChange={(event, editor) => onChangeHandler(event, editor)}
          />
          <div className={`${classes.btn_gropus} ${classes.flex_item}`}>
            <Button variant="contained" onClick={saveDescription}>
              Save
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEditableDescription(false)}>
              Discard
            </Button>
          </div>
        </div>
      </CardContent>
    ) : (
      <div
        className={classes.flex}
        onMouseEnter={() => setShowEditDescription(true)}
        onMouseLeave={() => setShowEditDescription(false)}
        style={{marginTop: 20}}>
        <Typography color="textPrimary">
          {parser(proposal.description || '')}
        </Typography>
        {showEditDescription && (
          <EditIcon
            onClick={() => setEditableDescription(true)}
            className={classes.edit_icon}
          />
        )}
      </div>
    );
  };

  const saveDescription = () => {
    const url = `${SERVER_URL}v1/proposals/${proposal.id}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'PUT',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({description: proposal.description}),
    }).then((res) => {
      setEditableDescription(false);
      props.updateRequestForProposalState();
    });
  };

  useEffect(() => {
    if (proposal) {
      getProfileName();
      const attachmentsData = proposal.attachmentReferences.filter(
        (ref: $TSFixMe) =>
          [
            'payment_receipt',
            'fees_invoice',
            'project_document',
            'contractor_payment_receipt',
            'proposal_invoice',
          ].includes(ref.key),
      );
      setAttachments(attachmentsData);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal]);

  const updateProjectQuote = (projectId: $TSFixMe) => {
    const rfp = {
      rfp_id: projectId.id,
    };
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/proposals/${proposal.id}`, {
      method: 'PUT',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify(rfp),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(projectId.title);
        props.updateRequestForProposalState();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /*
  const updateProposal = (body: $TSFixMe) => {
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/proposals/${proposal.id}`, {
      method: 'PUT',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((res) => {
        props.updateRequestForProposalState();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  */

  const rebuildPdf = () => {
    setRebuildingPdf(true);
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/proposals/${proposal.uid}/document`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setRebuildingPdf(false);
        setProposal(res);
      })
      .catch((err) => {
        setRebuildingPdf(false);
        console.error(err);
      });
  };

  if (!proposal) {
    return null;
  }

  return (
    <div>
      <Card className={classes.root}>
        <CardHeader title={getTitle()} subheader={getSubTitle()} />
        <div style={{marginTop: 30}}>
          <CardContent>
            <Grid
              container
              justifyContent="space-between"
              style={{marginTop: -30, marginBottom: 20}}
              spacing={1}>
              <Grid item sm={12} md={4}>
                <QuoteDetailsTable proposal={proposal} />
                {getDescription()}
                <Grid container spacing={2}>
                  <Grid item>
                    <CreateTransactionDialog
                      rfp_id={props.requestForProposal.id}
                      proposal_id={proposal.id}
                      proposal={proposal}
                      updateRequestForProposalState={
                        props.updateRequestForProposalState
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant={'contained'}
                      color={'secondary'}
                      disabled={rebuildingPdf}
                      onClick={rebuildPdf}>
                      {rebuildingPdf ? <Autorenew /> : 'REBUILD PDF'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <DocumentsList attachmentReferences={attachments} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={8}>
                <QuotePaymentScheduleTable
                  proposal={proposal}
                  requestForProposal={props.requestForProposal}
                  updateRequestForProposalState={
                    props.updateRequestForProposalState
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </div>
      </Card>
    </div>
  );
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: $TSFixMe) => { root: { m... Remove this comment to see the full error message
export default withStyles(styles)(ProposalSmallCard);
