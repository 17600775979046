import {withStyles} from '@material-ui/core/styles';
import {red} from '@material-ui/core/colors';
import React, {Component} from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import {isVideo} from '../../../util/VideoHelpers';
import moment from 'moment';
import RequestForProposalRiskStatusWidget from './RequestForProposalRiskStatusWidget';
import {getBrandColorForState} from '../../../util/BrandUtils';
import {CardContent} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';

const styles = (theme: $TSFixMe) => ({
  root: {
    minWidth: 250,
    maxWidth: 600,
    textAlign: 'left',
    margin: 12,
    borderRadius: '1rem',
    cursor: 'pointer',
    border: '1px solid transparent',
    '&:hover': {
      borderColor: 'black',
    },
  },

  media: {
    height: 300,
    width: '100%',
    paddingTop: '56.25%', // 16:9
  },

  avatar: {
    backgroundColor: red[500],
  },

  actions: {
    flex: 'initial',
  },
});

type SmallCardState = $TSFixMe;

class SmallCard extends Component<$TSFixMe, SmallCardState> {
  constructor(props: $TSFixMe) {
    super(props);
    this.state = {
      coverImage: process.env.PUBLIC_URL + '/images/logo.png',
      popoverOpen: false,
      popoverAnchorEl: null,
    };
  }

  setProfileName = () => {
    const firstName = (this.props as $TSFixMe).requestForProposal.firstName;
    const lastName = (this.props as $TSFixMe).requestForProposal.lastName;
    const displayName = `${firstName} ${lastName}`;
    this.setState({profileName: displayName});
  };

  getTitle = () => {
    let title = (this.props as $TSFixMe).requestForProposal.title;
    if (title.length > 17) {
      title = `${title.substr(0, 25)}...`;
    }
    return (
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <Typography variant="h6" color={title === ' ' ? 'error' : 'inherit'}>
            {title === ' ' ? 'MISSING TITLE' : title}
          </Typography>
          <Grid container spacing={3}>
            <Grid item>
              <Typography variant="subtitle2">
                Posted:{' '}
                {moment(
                  (this.props as $TSFixMe).requestForProposal.createdDate,
                ).format('MMMM Do')}
              </Typography>
            </Grid>
            {(this.props as $TSFixMe).requestForProposal.awardedDate !=
              null && (
              <Grid item>
                <Typography variant="subtitle2">
                  Awarded:{' '}
                  {moment(
                    (this.props as $TSFixMe).requestForProposal.awardedDate,
                  ).format('MMMM Do')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Typography style={{float: 'left'}}>
            {(this.props as $TSFixMe).requestForProposal.requesterName}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  getDescription = () => {
    let description = (this.props as $TSFixMe).requestForProposal.description;
    if (description.length > 200) {
      description = description.slice(0, 200) + '...';
    }
    return description;
  };

  getCardStats = () => {
    let bidStat = null;
    let questionsStat = null;
    let approvedForCallsIndicator = null;

    if ((this.props as $TSFixMe).requestForProposal.approvedForCalls) {
      approvedForCallsIndicator = <PhoneIcon />;
    }

    if (
      ['FUNDED', 'COMPLETE'].indexOf(
        (this.props as $TSFixMe).requestForProposal.state,
      ) === -1
    ) {
      if ((this.props as $TSFixMe).requestForProposal.proposalsCount > 0) {
        bidStat = (
          <Badge
            badgeContent={
              (this.props as $TSFixMe).requestForProposal.proposalsCount
            }
            color="primary">
            <AttachMoneyIcon />
          </Badge>
        );
      }

      if ((this.props as $TSFixMe).requestForProposal.questionsCount > 0) {
        questionsStat = (
          <Badge
            badgeContent={
              (this.props as $TSFixMe).requestForProposal.questionsCount
            }
            color="primary">
            <QuestionAnswerIcon />
          </Badge>
        );
      }
    }

    return (
      <Grid
        container
        justifyContent="flex-start"
        style={{marginTop: 6}}
        spacing={2}>
        <Grid item>
          {!(
            (this.props as $TSFixMe).requestForProposal.type === 'BIDMII_DIRECT'
          ) && (
            <Chip
              style={{textTransform: 'uppercase'}}
              size="small"
              label={
                (this.props as $TSFixMe).requestForProposal.city !== ' '
                  ? (this.props as $TSFixMe).requestForProposal.city
                  : 'NO LOCATION'
              }
            />
          )}
          <Chip
            style={{
              marginLeft: 5,
              backgroundColor: getBrandColorForState(
                (this.props as $TSFixMe).requestForProposal.state,
              ),
            }}
            size="small"
            color="primary"
            label={
              (this.props as $TSFixMe).requestForProposal.type ===
              'BIDMII_DIRECT'
                ? 'DIRECT'
                : (this.props as $TSFixMe).requestForProposal.state
            }
          />

          {!(
            (this.props as $TSFixMe).requestForProposal.type === 'BIDMII_DIRECT'
          ) &&
            !(this.props as $TSFixMe).requestForProposal.size && (
              <Chip
                style={{
                  marginLeft: 5,
                  backgroundColor: getBrandColorForState('CANCELLED'),
                }}
                size="small"
                color="primary"
                label={'Missing Scope'}
              />
            )}
        </Grid>
        {approvedForCallsIndicator && (
          <Grid item>{approvedForCallsIndicator}</Grid>
        )}
        {bidStat && <Grid item>{bidStat}</Grid>}
        {questionsStat && <Grid item>{questionsStat}</Grid>}
        <Grid item>
          <RequestForProposalRiskStatusWidget
            small={true}
            riskStatus={(this.props as $TSFixMe).requestForProposal?.riskStatus}
          />
        </Grid>
      </Grid>
    );
  };

  componentDidMount() {
    this.setProfileName();
  }

  render() {
    const {classes} = this.props;

    return (
      <Card
        className={classes.root}
        onClick={(this.props as $TSFixMe).onRfpClick}>
        <CardHeader title={this.getTitle()} subheader={this.getCardStats()} />
        {isVideo((this.props as $TSFixMe).requestForProposal.image) ? (
          <CardMedia
            style={{height: '56.25%', backgroundColor: 'black'}}
            component="video"
            image={
              (this.props as $TSFixMe).requestForProposal.image ||
              this.state.coverImage
            }
            autoPlay
            loop
            muted
          />
        ) : (
          <CardMedia
            style={{
              height: 0,
              paddingTop: '56.25%',
              borderTop: '1px solid #d9d9d9',
              borderBottom: '1px solid #d9d9d9',
            }}
            image={
              (this.props as $TSFixMe).requestForProposal.image ||
              this.state.coverImage
            }
          />
        )}
        <CardContent>
          <Typography variant="subtitle2">
            Homeowner: {this.state.profileName}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: $TSFixMe) => { root: { m... Remove this comment to see the full error message
export default withStyles(styles)(SmallCard);
